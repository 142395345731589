<template>
  <div class="full-height" rounded="0">
    <div class="d-flex flex-column justify-space-between full-height pt-6">
      <v-row justify="center" align="center" class="full-width" no-gutters>
        <v-col cols="11" sm="11" md="5" lg="5" xl="4">
          <div class="rounded-lg py-5">
            <transition :name="slideDirection" mode="out-in">
              <v-card
                v-if="form && currentSlide == keys.form"
                :key="keys.form"
                class="main-card text-left"
                elevation="0"
                rounded="2"
              >
                <div class="main-card-content mx-8">
                  <div class="d-flex justify-space-between align-start">
                    <v-card-title class="font-weight-bold pl-0 word-break">
                      {{ form.name }}
                    </v-card-title>
                    <v-btn
                      icon
                      v-if="$auth.isAuthenticated"
                      title="Close"
                      class="mt-3"
                      @click="exit"
                      ><v-icon>mdi-close-circle</v-icon></v-btn
                    >
                  </div>
                  <v-form v-model="validForm" onSubmit="return false;">
                    <p class="mt-4 mb-1">
                      Your info
                    </p>
                    <v-text-field
                      v-model="input.name"
                      autofocus
                      label="Name*"
                      color="brandCyan"
                      class=""
                      outlined
                      dense
                      :rules="formRules.stringRequired(255, 'name')"
                    ></v-text-field>
                    <v-text-field
                      v-model="input.email"
                      :rules="[
                        v => !!v || 'An email or phone number is required',
                        v =>
                          !!(v && v.length < 255) ||
                          'Your email/phone should be shorter',
                        v =>
                          !!(v && (emailRegex.test(v) || validPhone)) ||
                          'Your email/phone does not appear valid'
                      ]"
                      label="Email or Phone"
                      outlined
                      dense
                      color="brandCyan"
                      @change="phoneValidation"
                      @input="phoneValidation"
                    ></v-text-field>
                    <FormQuestion
                      v-for="(question, i) in form?.FormQuestions"
                      :key="question.formQuestionId"
                      :question="question"
                      :formId="formId"
                      @update-responses="updateAnswerArray($event, i)"
                    ></FormQuestion>
                    <v-file-input
                      v-if="form.allowFileUpload"
                      v-model="input.file"
                      label="Upload a file or photo"
                      outlined
                      clearable
                      hide-details
                      chips
                      color="brandCyan"
                      class="search-field search"
                      prepend-icon
                    >
                      <!-- <template v-slot:label>
                        <p>
                          Drag an image file, or click here to upload<v-icon
                            class="ml-2"
                            >mdi-upload</v-icon
                          >
                        </p>
                      </template> -->
                    </v-file-input>
                  </v-form>

                  <div class="d-flex flex-column align-center mt-9">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text"
                      width="120"
                      :disabled="!validForm || !ValidAnswers"
                      :loading="loading.submission"
                      @click="submitForm"
                      >Submit</v-btn
                    >
                    <p class="red--text text-center mt-5">{{ errorMessage }}</p>
                  </div>
                </div></v-card
              >
              <v-card
                v-else-if="currentSlide == keys.success"
                :key="keys.success"
                class="main-card text-left"
                elevation="0"
                rounded="2"
              >
                <div class="main-card-content mx-8">All done!</div>
              </v-card>
            </transition>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" align="start" class="full-width mt-3" no-gutters
        ><v-col>
          <span class="darkGrey--text"
            >{{ "Forms" }} | Powered by
            <a
              class="font-weight-bold darkGrey--text cursor-pointer text-decoration-none"
              href="https://wewhistle.com"
              target="_blank"
              >Whistle</a
            ></span
          >
        </v-col></v-row
      >
    </div>
    <!-- Dialog used while waiting on API response -->
    <v-dialog
      v-if="dialog.loadingAPI"
      v-model="dialog.loadingAPI"
      persistent
      width="500"
    >
      <v-card
        rounded="0"
        class="d-flex justify-center align-center flex-column py-6 px-10"
      >
        <div class="d-flex justify-space-between align-center">
          <v-card-title class="word-break text-center">
            {{
              dialog.loadingAPIErrorMessage
                ? "Error loading the form"
                : "Loading your form..."
            }}
          </v-card-title>
        </div>
        <div
          v-if="dialog.loadingAPIErrorMessage"
          class="word-break text-left mx-6 my-6"
        >
          {{ dialog.loadingAPIErrorMessage }}
        </div>
        <div v-else>
          <v-progress-circular
            :size="60"
            :width="5"
            color="brandCyan"
            indeterminate
            class="my-5"
          ></v-progress-circular>
          <p class="py-2 mx-10 text-left">
            Please wait while we load your form.
          </p>
        </div>
        <!-- Buttons -->
        <div v-if="dialog.loadingAPIErrorMessage">
          <v-card-actions class="mx-8">
            <v-btn
              color="brandCyan"
              outlined
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              @click="
                () => {
                  $router.push({
                    name: 'programs'
                  });
                }
              "
              >Exit</v-btn
            >
            <v-btn
              color="brandCyan"
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              depressed
              @click="loadForm"
              class="white--text"
              >Retry</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Survey from "../components/SurveyV2.vue";

import TokenService from "@/services/TokenService";
import LearningService from "@/services/LearningService";
import FormService from "@/services/FormService";

import FormQuestion from "@/components/forms/FormQuestion";

import { formRules, emailRegex } from "@/shared_data/data";

import { mapState } from "vuex";
import moment from "moment";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

export default {
  name: "Form",
  title: "Whistle | Forms",
  components: {
    FormQuestion
  },
  props: {},
  data() {
    return {
      formId: null,
      form: null,
      loading: {
        form: false,
        submission: false
      },
      dialog: {
        loadingAPI: false,
        loadingAPIErrorMessage: ""
      },
      currentSlide: 0,
      keys: {
        form: 0,
        success: 1,
        error: 2
      },
      validForm: null,
      formRules,
      emailRegex,
      validPhone: false,
      input: {
        name: null,
        email: null,
        phone: null,
        file: null
      },
      answers: [],

      // Routing
      previousRoute: null,

      // OLD
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,

      // Entire object
      survey: null,
      email: null,
      token: null,
      valid: false,
      loadingSurvey: false,
      forcedLinearProgress: null,
      // First incomplete chapter
      currentChapterIndex: null,
      // currentLesson is the strinified version of the first incomplete card
      currentLesson: null,
      // First incomplete lesson
      currentLessonIndex: null,
      surveyCompleted: null,
      errorMessage: null,
      slideDirection: "topic-left"
    };
  },
  mounted() {
    this.formId = Number(this.$route.params.formId);
    this.loadForm();

    if (this.$auth.isAuthenticated) {
      this.input.name =
        this.userProfile?.displayName ||
        this.userProfile?.firstName + " " + this.userProfile?.lastName;
      this.input.email =
        this.userProfile?.businessEmail || this.userProfile?.businessPhone;
      this.input.phone = this.userProfile?.businessPhone;
    }
  },
  beforeDestroy() {},
  beforeRouteEnter(to, from, next) {
    next(vm => (vm.previousRoute = from));
  },
  methods: {
    exit() {
      // Added this so Router keeps previous query params if coming from programs
      if (this.previousRoute?.name === "programs") this.$router.back();
      else
        this.$router.push({
          name: "programs",
          query: {
            filterBy: "forms"
          }
        });
    },
    async loadForm() {
      this.loading.form = true;
      this.dialog.loadingAPI = true;
      this.dialog.loadingAPIErrorMessage = "";
      try {
        const res = await FormService.getFormsV2(this.formId, undefined, {
          includeQuestions: true
        });
        console.log("Got form ", res?.result);
        this.form = res?.result;
        if (!this.form) throw { status: 404 };

        this.dialog.loadingAPI = false;
      } catch (e) {
        console.log("Failed to load form", e);
        this.dialog.loadingAPIErrorMessage =
          "There was an issue loading your form. Please try again or contact us if you need further assistance.";
      } finally {
        this.loading.form = false;
      }
    },
    async submitForm() {
      this.loading.submission = true;
      // this.dialog.loadingAPI = true;
      this.dialog.loadingAPIErrorMessage = "";

      let submission = {
        creatorName: this.input.name,
        creatorEmail: this.input.phone
          ? this.userProfile?.businessEmail
          : this.input.email,
        creatorPhone: this.input.phone,
        creatorUserId: this.$auth.isAuthenticated
          ? this.userProfile?.userId
          : null,
        FormSubmissionValues: this.FlatAnswers.map(x => {
          delete x.required;
          return x;
        })
      };
      try {
        const res = await FormService.createFormSubmissionV2(
          this.formId,
          submission
        );
        console.log("Created submission ", res?.result);

        this.currentSlide = this.keys.success;
      } catch (e) {
        console.log("Failed to load form", e);
        this.dialog.loadingAPIErrorMessage =
          "There was an issue loading your form. Please try again or contact us if you need further assistance.";
      } finally {
        this.loading.submission = false;
      }
    },
    phoneValidation: function(val) {
      let isValid = false;
      isValid = isValidPhoneNumber(val, "US");
      this.validPhone = isValid;
      if (isValid)
        this.input.phone = parsePhoneNumber(val, "US").format("E.164");
      else this.input.phone = null;
    },
    updateAnswerArray(event, index) {
      this.$set(this.answers, index, event.flat());
    },
    captureEmail() {
      // Clearing error message in case of invalid email
      if (!this.valid || this.loadingSurvey) return;
      this.errorMessage = null;
      this.getSurveyAndToken();
    },
    getSurveyAndToken() {
      this.loadingSurvey = true;
      console.log("getting survey and token!", this.email);

      // Clearing error message JUST in case!
      this.errorMessage = null;
      LearningService.getPublicSurveyAndToken(this.surveyId, this.email)
        .then(response => {
          console.log("WOOT RESPONSE AHEAD!");
          console.log(response);

          // this is checking for an empty response
          if (Object.values(response).length > 0) {
            const survey = JSON.parse(JSON.stringify(response.data.module));
            const token = JSON.parse(JSON.stringify(response.data.token));
            this.token = token;
            this.survey = survey;
            console.log("SURVEY!!!!", this.survey);

            if (this.survey.LearningChapters.length >= 1) {
              // This boolean will flip once we parse the chapters/cards and we find the first instance of something not completed. Then we set that as our starting point.
              // TODO: Not sure about this logic and how it will be for surveys
              var completeBool = false;
              this.survey.LearningChapters.forEach((chapter, chapterIndex) => {
                // looping through cards (Q's) in chapter
                chapter.LearningCards.forEach((card, cardIndex) => {
                  // iterating through cards, executes first incomplete card (Q)
                  if (
                    (!card.latestLearningLog ||
                      (card.latestLearningLog &&
                        !card.latestLearningLog.completed)) &&
                    !completeBool
                  ) {
                    this.currentChapterIndex = chapterIndex;
                    this.currentLessonIndex = cardIndex;
                    // copy of card object
                    this.currentLesson = JSON.parse(JSON.stringify(card));
                    console.log(
                      "!!!! Found card that isn't completed !!!!",
                      card
                    );

                    // completed is probably false since that was the condition of the block
                    this.surveyCompleted = card.latestLearningLog
                      ? card.latestLearningLog.completed
                      : false;
                    // stops loop after first incomplete card
                    completeBool = true;
                  }

                  // Finally, we check if completeBool was never flipped, aka they finished the entire thing. If so, we set the current chpt and card index to last
                  // We only check this if we've cycled through all chapters and cards and are at the end
                  if (
                    !completeBool &&
                    chapterIndex == this.survey.LearningChapters.length - 1 &&
                    cardIndex == chapter.LearningCards.length - 1
                  ) {
                    console.log(
                      "Entire survey is complete",
                      this.survey.LearningChapters[chapterIndex]
                    );
                    this.currentChapterIndex = chapterIndex;

                    if (
                      this.survey.LearningChapters[chapterIndex].LearningCards
                        .length > 0
                    ) {
                      this.currentLessonIndex = cardIndex;
                      this.currentLesson = JSON.parse(
                        JSON.stringify(
                          this.survey.LearningChapters[chapterIndex]
                            .LearningCards[cardIndex]
                        )
                      );
                      console.log(this.currentLesson);
                      this.surveyCompleted = true;
                    }
                  }
                });
              });
            }
          } else {
            // TODO: handle errors, this one is probably a weird error if we made it here
            console.log("!!!!ERROR!!!!!");
            this.errorMessage = "Something went wrong, please try again.";
          }
        })
        .catch(err => {
          console.log("Error when fetching survey ", err);
          let errorCode = err.data ? err.data.error_code : "";
          let code = Number(errorCode.slice(-2));
          switch (code) {
            case 11:
              this.errorMessage = "Invalid email address, please try again.";
              this.email = null;
              break;
            case 12:
              this.errorMessage =
                "You'll need to sign in to your Whistle account to take this survey.";
              break;
            case 13:
              this.errorMessage =
                "Whoops, we're having trouble finding that survey.";
              break;
            case 14:
              this.errorMessage =
                "It looks like you've already taken this survey.";
              break;
            default:
              this.errorMessage = "Something went wrong. Please try again.";
          }
        })
        .finally(() => {
          this.loadingSurvey = false;
        });
    },
    isTokenValid() {
      if (
        moment(this.token.createdAt).add(this.token.tokenTTL, "seconds") <
        moment().utc()
      ) {
        return false;
      } else {
        return true;
      }
    },
    getNewToken() {
      console.log("!!!!!!!In public survey, getting new token!!!!!!!");
      let tokenArr = [];
      let body = {
        clientId: this.token.clientId,
        externalUserId: this.token.externalUserId,
        ipAddress: null,
        context: this.token.context,
        contextResourceId: this.token.contextResourceId,
        tokenTTL: this.token.tokenTTL,
        numUses: this.token.numUses,
        createdBy: this.token.createdBy,
        updatedBy: this.token.createdAt
      };
      return TokenService.createToken(body).then(res => {
        tokenArr = res;
        let sortedArr = tokenArr.sort((a, b) => {
          return moment(a.createdAt).diff(b.createdAt);
        });
        let latestToken = sortedArr[tokenArr.length - 1];
        return latestToken;
      });
    },
    // This accepts a boolean to tell the functino if we return the log array rather than inserting
    // This way we can compile an array rather than inserting individually.
    // The extraLogs are the previous array of logs passed in.
    async postSurveyLearningLog(returnLog = false, extraLogs = []) {
      if (this.isTokenValid() === false) {
        let newToken = await this.getNewToken();
        this.token = newToken;
      }
      console.log("Posting Survey Learning Log!!");
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      var body = {
        externalUserId: this.token.externalUserId,
        userId: this.userProfile ? this.userProfile.userId : null,
        clientId: this.token.clientId,
        learningCardId: this.currentLesson.learningCardId,
        learningCardAssociationId: this.currentLesson.learningCardAssociationId,
        completed: true,
        completedDate: dateTime,
        learnerRating: null,
        learnerConfidence: null,
        score: null
      };

      console.log("-------------this is the new log-------------");
      console.log(body);

      // we must await the Promise result here since we call it kind of recursively
      var logs = await extraLogs;
      logs.push(body);

      if (!returnLog) {
        console.log("Inserting " + logs.length + " learning logs.");
        LearningService.createLearningLog(logs, [], this.token.token);
      } else {
        return logs;
      }
    },
    restartSurvey() {
      console.log("restarting survey!!!");
      // reset data
      this.surveyCompleted = false;
      this.currentChapterIndex = 0;
      this.currentLessonIndex = 0;

      // with a public survey, the data objects look a little weird
      // each learningCard has a .LearningCard obj as well, but survey fetches the card itself with the id
      this.currentLesson = JSON.parse(
        JSON.stringify(
          this.survey.LearningChapters[this.currentChapterIndex].LearningCards[
            this.currentLessonIndex
          ]
        )
      );
      console.log(this.currentLesson);
      console.log("reset current lesson");
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "postItArray"]),
    FlatAnswers() {
      return this.answers.flat().filter(Boolean);
    },
    ValidAnswers() {
      return !this.FlatAnswers.find(a => a.required && !a.textValue);
    },
    isSurveyRepeatable() {
      if (!this.survey || !this.survey.LearningChapters) return false;
      let repeatBool = false;
      // loop through all cards and if any are repeatable flip boolean!!
      // TODO: check if this is an okay way to handle things
      for (let i = 0; i < this.survey.LearningChapters.length; i++) {
        let currentCh = this.survey.LearningChapters[i];
        for (let j = 0; j < currentCh.LearningCards.length; j++) {
          if (currentCh.LearningCards[j].repeatable == true) {
            repeatBool = true;
            break;
          }
        }
      }
      return repeatBool;
    },
    isConclusion() {
      // surveys have one chapter and a conclusion chapter
      // if chIndex is 1, it's the conclusion
      return this.currentChapterIndex === 1;
    }
  }
};
</script>

<style scoped>
/* Controls styling for the card component in
the main container. Shows survey card */
.main-card {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px 10px 10px 10px !important;
  height: auto;
  min-height: 50vh;
}

/* Inner content for main card so it has smaller margins when on mobile*/
.main-card-content {
  min-width: 60%;
  height: 100%;
  min-height: 60vh;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (min-width: 960px) {
  .main-card-content {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media screen and (min-width: 451px) {
  .main-card-content {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 450px) {
  .main-card-content {
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
