<template>
  <div v-if="question">
    <v-card rounded="0" width="100%" elevation="0" class="word-break">
      <!-- <v-card-title class="word-break px-0 font-weight-bold brand--text">
        {{ question.label }}
      </v-card-title> -->
      <v-textarea
        v-if="question.type == 'text'"
        outlined
        color="brandCyan"
        :label="Label"
        v-model.lazy="textValue"
        class="rounded"
      ></v-textarea>
      <v-autocomplete
        v-else-if="
          ['dropdown', 'project', 'category', 'subcategory'].includes(
            question.type
          )
        "
        outlined
        dense
        color="brandCyan"
        class="rounded"
        :label="Label"
        returnObject
        item-text="label"
        item-value="formQuestionValueId"
        v-model="formQuestionValueId"
        :items="question?.FormQuestionValues || []"
        no-data-text="No options found"
        :disabled="loadingChildQuestion"
        @change="userInputChanged"
      ></v-autocomplete>
      <div v-else-if="question.type === 'radio'">
        <p class="mb-1">{{ Label }}</p>

        <v-radio-group
          v-model="formQuestionValueId"
          row
          @change="userInputChanged"
          ><v-radio
            v-for="val in question?.FormQuestionValues || []"
            :key="val.formQuestionValueId"
            :label="val.label"
            :value="val.formQuestionValueId"
            color="brandCyan"
            class="mb-3"
          ></v-radio
        ></v-radio-group>
      </div>
      <div class="d-flex justify-center pb-4" v-if="loadingChildQuestion">
        <v-progress-circular
          indeterminate
          color="brandCyan"
          size="25"
          width="3"
        />
      </div>
      <FormQuestion
        v-else-if="childQuestion"
        :key="childQuestion.formQuestionId"
        :question="childQuestion"
        :formId="formId"
        @update-responses="updateAnswerArray($event, 0)"
      ></FormQuestion>
    </v-card>
  </div>
</template>

<script>
import FormService from "@/services/FormService";

import FormQuestion from "@/components/forms/FormQuestion";

import { mapState } from "vuex";

export default {
  name: "FormQuestion",
  props: {
    question: Object,
    formId: Number,
    whistleToken: String
  },
  components: { FormQuestion },
  data() {
    return {
      childAnswers: [],
      textValue: "",
      selectedValue: "",
      formQuestionValueId: null,
      childQuestionId: null,
      childQuestion: null,
      loadingChildQuestion: false,
      childQuestionError: ""
    };
  },
  created() {
    console.log("Loading question", this.question);
    // We set this from an empty string to null just so the computed Output triggers a change to initialize the answers array
    this.textValue = null;
  },
  updated() {},
  mounted() {},
  beforeMount() {},
  methods: {
    async getChildQuestion(id) {
      this.childQuestionId = id;
      this.loadingChildQuestion = true;
      this.childQuestionError = "";
      try {
        const res = await FormService.getFormQuestionV2(id, undefined, {
          includeQuestions: true
        });

        if (id !== this.childQuestionId)
          return console.log("Value no longer selected - terminate");
        console.log("Got form ", res?.result);
        this.childQuestion = res?.result;
        if (!this.childQuestion) throw { status: 404 };

        // this.dialog.loadingAPI = false;
      } catch (e) {
        console.log("Failed to load form", e);
        this.childQuestionError =
          "There was an issue loading your form. Please try again or contact us if you need further assistance.";
      } finally {
        this.loadingChildQuestion = false;
      }
    },
    userInputChanged(e) {
      let answer = e;
      if (typeof e !== "object")
        answer = this.question.FormQuestionValues.find(
          x => e === x.formQuestionValueId
        );
      console.log("userInputChanged", { e, answer });
      this.formQuestionValueId = answer?.formQuestionValueId;
      this.textValue = answer?.label;
      if (answer?.childQuestionId) {
        console.log("Need to fetch child question");
        this.getChildQuestion(answer?.childQuestionId);
      } else {
        // Wipe values so if there's a child question, we no longer show it
        this.childQuestionId = null;
        this.childQuestion = null;
        // Wipes the child's answer from the answer array
        this.updateAnswerArray(undefined, 0);
      }
    },
    updateAnswerArray(e, i) {
      console.log("updateAnswerArray: " + this.question.formQuestionId, {
        i,
        e
      });
      this.$set(this.childAnswers, i, e);
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    Label() {
      return this.question?.label + (this.question?.required ? " *" : "");
    },
    // Formats FormSubmissionValue object
    Output() {
      return {
        textValue: this.textValue,
        // formId: this.formId,
        formQuestionId: this.question?.formQuestionId,
        formQuestionValueId: this.formQuestionValueId,
        required: !!this.question?.required
      };
    }
  },
  watch: {
    childAnswers: {
      deep: true,
      handler(n) {
        console.log(
          "child answers changed: " + this.question.formQuestionId,
          n
        );
        this.$emit("update-responses", [this.Output, n].flat().filter(Boolean));
      }
    },
    Output: function(n) {
      console.log("output changed: " + this.question.formQuestionId, n);
      this.$emit("update-responses", [n, this.childAnswers].flat());
    }
  }
};
</script>

<style scoped></style>
