import axios from "../axios";

const baseUrl =
  (process.env.VUE_APP_DEV_LOCAL && process.env.VUE_APP_FORM_URL) ??
  `${process.env.VUE_APP_WHISTLE_API}/form-service`;

class FormService {
  // V2
  getFormsV2(formId = undefined, clientId = undefined, params = {}) {
    return axios
      .get(`${baseUrl}/v2/form${formId ? `/${formId}` : ``}`, {
        params: { ...params, clientId }
      })
      .then(r => {
        return r?.data;
      });
  } // end getFormsV2

  getFormQuestionV2(formQuestionId = undefined, params = {}) {
    return axios
      .get(`${baseUrl}/v2/form-question/${formQuestionId}`, {
        params
      })
      .then(r => {
        return r?.data;
      });
  } // end getFormQuestionV2

  createFormSubmissionV2(formId, body = {}, params = {}) {
    return axios
      .post(`${baseUrl}/v2/form-submission/${formId}`, body, { params })
      .then(r => {
        return r?.data;
      });
  } // end createFormSubmissionV2
}

export default new FormService();
