<template>
  <div class="full-height">
    <v-card width="100%" rounded="0" class="full-height">
      <div id="header" class="d-flex justify-start align-center" width="100%">
        <v-icon large dark class="pa-2 ml-3 my-2 brand-background border-radius"
          >mdi-star</v-icon
        >
        <v-card-title> Programs </v-card-title>
      </div>
      <v-divider class="mx-4" />

      <v-row class="my-2 ml-3 mr-2">
        <v-col cols="12" md="3">
          <!-- <h2 class="align-text-left mb-4">
            Welcome back!
          </h2> -->
          <div
            :class="
              $vuetify.breakpoint.sm === true ? 'd-flex small-screen-div' : ''
            "
          >
            <!-- Buttons to toggle surveys and programs -->
            <!-- arranged as a row or column on md and down screens -->
            <div
              class="d-flex type-filter-div flex-wrap"
              :class="
                $vuetify.breakpoint.xs === true
                  ? 'justify-flex-start'
                  : 'justify-center'
              "
            >
              <!-- <v-spacer></v-spacer> -->
              <v-btn
                class="mb-3 mx-1 white--text type-filter-btn"
                :class="filterBy.includes('learning') ? 'active-btn' : ''"
                :color="filterBy.includes('learning') ? 'brand' : 'grey'"
                @click="updateFilterBy('learning')"
                :disabled="loadingPrograms"
                elevation="0"
              >
                <v-icon class="mr-1" dark small>mdi-school</v-icon>
                <p class="ma-0 caption">
                  Learning
                </p>
              </v-btn>
              <v-btn
                class="mb-3 mx-1 white--text type-filter-btn"
                :class="filterBy.includes('surveys') ? 'active-btn' : ''"
                :color="filterBy.includes('surveys') ? 'brand' : 'grey'"
                @click="updateFilterBy('surveys')"
                :disabled="loadingPrograms"
                elevation="0"
              >
                <v-icon class="mr-1" dark small
                  >mdi-comment-question-outline</v-icon
                >
                <p class="ma-0 caption">
                  Surveys
                </p>
              </v-btn>
              <v-btn
                class="mb-3 mx-1 white--text type-filter-btn"
                :class="filterBy.includes('forms') ? 'active-btn' : ''"
                :color="filterBy.includes('forms') ? 'brand' : 'grey'"
                @click="updateFilterBy('forms')"
                :disabled="loading.forms"
                :loading="loading.forms"
                elevation="0"
              >
                <v-icon class="mr-1" dark small>mdi-form-dropdown</v-icon>
                <p class="ma-0 caption">
                  Forms
                </p>
              </v-btn>
            </div>
          </div>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="9">
          <div class="d-flex justify-space-between align-center mr-2 mb-3">
            <!-- <v-btn text color="primary" class="mr-2" v-bind="attrs" v-on="on"
              >Filter</v-btn
            > -->

            <!-- Filter and Sort -->
            <div class="d-flex">
              <v-menu
                v-if="!filterBy.includes('forms')"
                v-model="filters.showMenu"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    >Filter</v-btn
                  >
                </template>
                <v-list>
                  <v-list-item v-if="filterBy.includes('learning')">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="filterBy.includes('active-programs')"
                        :true-value="true"
                        :false-value="false"
                        @click="updateFilterBy('active-programs')"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-icon class="mr-3">
                      <v-icon>mdi-brain</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="align-text-left">
                      <v-list-item-title>Active Programs</v-list-item-title>
                    </v-list-item-content>
                    <!-- </template> -->
                  </v-list-item>
                  <v-list-item v-if="filterBy.includes('learning')">
                    <!-- <template v-slot:default="{ active }"> -->
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="filterBy.includes('future-programs')"
                        :true-value="true"
                        :false-value="false"
                        @click="updateFilterBy('future-programs')"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-icon class="mr-3">
                      <v-icon>mdi-chart-box</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="align-text-left">
                      <v-list-item-title>Future Programs</v-list-item-title>
                    </v-list-item-content>
                    <!-- </template> -->
                  </v-list-item>
                  <v-list-item v-if="filterBy.includes('learning')">
                    <!-- <template v-slot:default="{ active }"> -->
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="filterBy.includes('archived-programs')"
                        :true-value="true"
                        :false-value="false"
                        @click="updateFilterBy('archived-programs')"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-icon class="mr-3">
                      <v-icon>mdi-format-list-text</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="align-text-left">
                      <v-list-item-title>Archived Programs</v-list-item-title>
                    </v-list-item-content>
                    <!-- </template> -->
                  </v-list-item>
                  <v-list-item v-if="filterBy.includes('surveys')">
                    <!-- <template v-slot:default="{ active }"> -->
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="filterBy.includes('completed-surveys')"
                        :true-value="true"
                        :false-value="false"
                        @click="updateFilterBy('completed-surveys')"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-icon class="mr-3">
                      <v-icon>mdi-comment-question-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="align-text-left">
                      <v-list-item-title>Completed Surveys</v-list-item-title>
                    </v-list-item-content>
                    <!-- </template> -->
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                v-model="sortMenu"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex">
                    <v-btn
                      text
                      color="primary"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      >Sort</v-btn
                    >
                    <!-- <v-btn
                    text
                    color="primary"
                    class="mr-2"
                    @click="sortAsc = !sortAsc"
                    ><v-icon v-if="sortAsc" class="mr-2">mdi-arrow-up</v-icon
                    ><v-icon v-else class="mr-2">mdi-arrow-down</v-icon
                    >Sort</v-btn
                  > -->
                  </div>
                </template>
                <v-list>
                  <v-list-item
                    v-for="option in sortOptions"
                    :key="option.id"
                    @click="updateSortBy(option)"
                    :class="{
                      'light-grey-background': option.values.includes(sortBy)
                    }"
                  >
                    <v-list-item-content class="align-text-left">
                      <v-list-item-title>{{ option.label }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon
                      :class="{ hidden: !option.values.includes(sortBy) }"
                      ><v-icon>{{
                        sortBy && sortBy.includes("asc")
                          ? "mdi-arrow-up"
                          : "mdi-arrow-down"
                      }}</v-icon></v-list-item-icon
                    >
                    <!-- </template> -->
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <!-- Search Bar -->
            <v-text-field
              outlined
              placeholder="Search"
              prepend-icon="mdi-magnify"
              single-line
              dense
              hide-details
              v-model="search"
              class="shrink"
            ></v-text-field>
          </div>
          <v-divider />

          <div
            class="d-flex align-center justify-space-between"
            v-if="loadingPrograms"
          >
            <div
              class="d-flex align-center full-width"
              :class="{
                'flex-column': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              }"
            >
              <v-skeleton-loader
                :max-width="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                    ? '500'
                    : '450'
                "
                :width="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                    ? '100%'
                    : '450'
                "
                :max-height="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                    ? '281'
                    : '253'
                "
                height="253"
                class="py-4 px-2"
                type="image"
              ></v-skeleton-loader>
              <v-skeleton-loader
                max-width="280"
                width="280"
                class="pa-4"
                type="text, text, text, text"
              ></v-skeleton-loader>
            </div>

            <!-- <div class="d-flex align-center">
              <v-skeleton-loader
                class="pa-4 skeloton-progress-circle"
                type="avatar"
              ></v-skeleton-loader>
              <v-skeleton-loader
                max-width="175"
                width="175"
                class="pa-4 ml-4"
                type="text, text, text"
              ></v-skeleton-loader>
            </div> -->
          </div>
          <v-container v-else-if="loadingError">
            <b class="word-wrap"
              >We had some trouble loading your programs.
              <a @click="getProgramsByUser">Try again</a></b
            >
            <lottie
              :options="lottieOptions"
              :height="150"
              :width="150"
              v-on:animCreated="handleAnimation"
              class="mt-5"
            />
          </v-container>
          <v-container
            v-else-if="
              computedVisiblePrograms.length === 0 &&
                !filters.active &&
                !filters.archived &&
                !filters.future &&
                !this.filterBy.includes('forms')
            "
          >
            <b class="word-wrap">Looks like no filters are selected</b>
            <lottie
              :options="lottieOptions"
              :height="150"
              :width="150"
              v-on:animCreated="handleAnimation"
              class="mt-5"
            />
          </v-container>
          <v-container
            v-else-if="computedVisiblePrograms.length === 0 && search"
          >
            <b class="word-wrap">Looks like no results were found</b>
            <lottie
              :options="lottieOptions"
              :height="150"
              :width="150"
              v-on:animCreated="handleAnimation"
              class="mt-5"
            />
          </v-container>
          <v-container v-else-if="computedVisiblePrograms.length === 0">
            <b class="word-wrap"
              >We couldn't find any
              {{ filterBy.includes("forms") ? "forms" : "programs" }} for you</b
            >
            <v-img
              src="@/assets/Placeholder_Screens/Knowledge.png"
              max-width="400"
              width="40%"
              min-width="300"
              class="mx-auto"
            />
          </v-container>
          <v-container v-else>
            <!-- Div used as a v-for and arranged in columns because it has a v-divider beneath each program -->
            <div
              v-for="program in computedVisiblePrograms"
              :key="program.programId"
              class="d-flex flex-column full-width cursor-pointer"
              @click="loadProgram(program)"
            >
              <!-- xs, sm, md title and date, hidden on lg and up -->
              <!-- <div
                class="word-break full-width small-screen-title space-between mr-3 d-xs-flex d-lg-none d-xl-none"
              >
                <div class="d-flex flex-column align-start">
                  <h2 class="word-break d-flex wrap">
                    {{ program.displayName }}
                  </h2>
                  <div class="d-flex align-baseline program-date-string">
                    <span class="mr-2">{{ program.formattedDateString }}</span>
                    <div class="d-flex">
                      <div
                        v-if="program.mandatory"
                        class="d-flex mt-4 mr-3 error--text mandatory-flag wrap"
                      >
                        <v-icon color="error" size="16" class="mr-1"
                          >mdi-asterisk</v-icon
                        >
                        <p class="ma-0 font-weight-bold">MANDATORY</p>
                      </div>
                      <TODO: new flag goes her! -->
              <!-- <div
                        v-if="daysSinceStart(program) <= 7"
                        class="d-flex mt-4 brandGreen--text new-flag wrap d-none"
                      >
                        <v-icon
                          color="brandGreen"
                          size="14"
                          class="mr-1 new-flag-icon"
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        <p class="ma-0 font-weight-bold">NEW</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- Div arranged as row and justify space between so the image/title are on opposite sides from the progress stats -->
              <div
                class="d-flex my-3 align-center justify-space-between full-width full-height "
                :class="{
                  'flex-column':
                    $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
                  'program-card-div':
                    !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
                }"
              >
                <!-- Div used to keep the image and title bundled together -->
                <div
                  class="d-flex align-center full-width"
                  :class="{
                    'flex-column':
                      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  }"
                >
                  <v-img
                    v-if="program.imageURL || program.imageUrl"
                    :src="program.imageURL || program.imageUrl"
                    :aspect-ratio="16 / 9"
                    class="img mr-4"
                    :width="
                      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                        ? '80%'
                        : $vuetify.breakpoint.md
                        ? '350'
                        : '450'
                    "
                    :max-width="
                      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                        ? '400'
                        : $vuetify.breakpoint.md
                        ? '350'
                        : '450'
                    "
                    height="auto"
                  />
                  <v-img
                    v-else
                    src="@/assets/placeholder_image.png"
                    :aspect-ratio="16 / 9"
                    class="img mr-4"
                    :width="
                      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                        ? '80%'
                        : $vuetify.breakpoint.md
                        ? '350'
                        : '450'
                    "
                    :max-width="
                      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                        ? '400'
                        : $vuetify.breakpoint.md
                        ? '350'
                        : '450'
                    "
                    height="auto"
                  />

                  <div
                    :style="{
                      width:
                        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                          ? '80%'
                          : $vuetify.breakpoint.md
                          ? '100%'
                          : '100%'
                    }"
                    :max-width="
                      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                        ? '80%'
                        : $vuetify.breakpoint.md
                        ? '100%'
                        : '100%',
                    "
                  >
                    <div
                      class="d-flex flex-column full-width align-start mt-2"
                      :class="{
                        'ml-4':
                          !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
                      }"
                    >
                      <div
                        class="d-flex align-center"
                        :class="{
                          'mt-4':
                            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                        }"
                      >
                        <v-icon v-if="program.formId" class="ml-2 mr-4" medium
                          >mdi-form-dropdown</v-icon
                        >
                        <v-icon
                          v-else-if="program.isSurvey === true"
                          class="ml-2 mr-4"
                          medium
                          >mdi-comment-question-outline</v-icon
                        >
                        <v-icon v-else class="ml-2 mr-4" medium
                          >mdi-school</v-icon
                        >
                        <h2 class="word-break align-text-left">
                          {{ program.displayName || program.name }}
                        </h2>
                      </div>
                      <!-- TODO: OMG THIS -->
                      <div
                        class="d-flex justify-center full-width mt-4 mb-4"
                        v-if="
                          program.startDate &&
                            program.startDate !== null &&
                            program.startDate !== undefined
                        "
                      >
                        <ProgramCardProgress
                          :status="program.status"
                          :startDate="program.startDate"
                          :endDate="program.endDate"
                          :progressPercent="program.progressPercent"
                          class="full-width"
                          :class="{
                            'ml-2':
                              !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
                          }"
                          :hideClock="false"
                          :renderClock="false"
                          align="start"
                        />
                      </div>
                      <!-- TODO: Survey progress bar -->
                      <div
                        class="d-flex justify-center full-width mt-4 mb-4 progress-bar-div"
                        v-else-if="!program.formId"
                      >
                        <v-progress-linear
                          :value="program.progressPercent"
                          color="brandGreen"
                          height="22"
                          class="progress-bar mr-auto"
                          :class="{
                            'ml-12':
                              !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
                          }"
                        ></v-progress-linear>
                      </div>
                    </div>
                    <div class="d-flex align-text-left">
                      <p class="mt-3 ml-6 program-description">
                        {{
                          program.formId
                            ? program.description
                            : program.descriptionNoTags
                        }}
                      </p>
                    </div>
                    <div
                      v-if="program.mandatory"
                      class="d-flex mt-2 ml-6 error--text mandatory-flag"
                    >
                      <v-icon color="error" size="16" class="mr-1"
                        >mdi-asterisk</v-icon
                      >
                      <p class="ma-0 font-weight-bold">MANDATORY</p>
                    </div>
                    <div class="d-flex align-left">
                      <div
                        v-if="daysSinceStart(program) <= 7"
                        class="d-flex mt-1 ml-6 brandGreen--text new-flag wrap d-none"
                      >
                        <v-icon
                          color="brandGreen"
                          size="14"
                          class="mr-2 new-flag-icon"
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        <p class="ma-0 font-weight-bold">NEW</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider />
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import ProgramService from "../services/ProgramService.js";
import FormService from "@/services/FormService";
// import Robin from "@/components/Robin.vue";
import ProgramCardProgress from "@/components/ProgramCardProgressV2.vue";
// import ProgressBar from "@/components/ProgressBar.vue";
import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/robin/Curious.json";

import { mapState } from "vuex";
import moment from "moment";
import fz from "fuzzaldrin-plus";

export default {
  name: "Programs",
  title: "Whistle | Programs",
  components: {
    // Robin,
    ProgramCardProgress,
    // ProgressBar,
    Lottie
  },
  data() {
    return {
      lottieOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true
      },
      loading: {
        forms: false
      },
      loadingPrograms: true,
      loadingError: false,
      search: null,
      filters: {
        showMenu: false,
        active: true,
        archived: false,
        future: false,
        completedSurveys: false
      },
      sortOptions: [
        {
          label: "Name",
          key: "name",
          values: ["name-asc", "name-desc"]
        },
        {
          label: "Progress",
          key: "progress",
          values: ["progress-asc", "progress-desc"]
        },
        {
          label: "Time Remaining",
          key: "time-remaining",
          values: ["time-remaining-asc", "time-remaining-desc"]
        }
      ],
      sortMenu: false,
      // sortBy: { id: 1, label: "Name A to Z" },
      futurePrograms: [],
      activePrograms: [],
      archivedPrograms: [],
      selectedProgram: null,
      surveys: [],
      forms: [],
      // activeSurveys: [],
      // futureSurveys: [],
      // archivedSurveys: [],
      // Type filter is either null (both programs and surveys), surveys, or programs
      // computedVisiblePrograms uses this to know if it should add the this.surveys array
      typeFilter: null
      // surveyCount: null,
      // learningCount: null,
    };
  },
  created() {
    var sortBy = null;
    var filterBy = null;
    if (!this.$route.query.sortBy) sortBy = "time-remaining-asc";
    // else obj.sortBy = this.$route.query.sortBy;
    if (!this.$route.query.filterBy) filterBy = ["learning", "active-programs"];
    // else obj.filterBy = this.$route.query.filterBy;

    this.replaceQuery(sortBy, filterBy);
    if (this.$auth) {
      this.getProgramsByUser();
      this.getForms();
    }

    if (this.$route.name == "giving") this.loadGivingWidget();

    // this.unwatch = this.$store.watch(
    //   (state) => {
    //     return state.loadItemFromSearch; // could also put a Getter here
    //   },
    //   (programId) => {
    //     console.log(`User clicked item in search bar`);
    //     console.log(programId);
    //     if (programId !== null) {
    //       this.loadProgramById(programId);
    //       //We wipe the ID storede in the store after we load it up
    //       this.$store.dispatch("setLoadItemFromSearch", null);
    //     }
    //   },
    //   //Optional Deep if you need it
    //   {
    //     deep: true,
    //   }
    // );
  },
  // beforeDestroy() {
  //   //Clears the search in case they go to a page without search
  //   this.$store.dispatch("setSearchArray", []);
  //   this.unwatch();
  // },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    async getProgramsByUser() {
      var futurePrograms = [];
      var activePrograms = [];
      var archivedPrograms = [];

      this.loadingPrograms = true;
      this.loadingError = false;
      try {
        var programs = (await ProgramService.getProgramsByUserV2(null, true))
          .rows;
        console.log(programs);
        if (programs) {
          // filter out isSurvey modules to format and display later
          // for now, the endpoint returns them and i dont want to break dev over the weekend!
          console.log("filtering out surveys", programs.length);
          let surveysArr = [];
          let programsArr = [];
          // for the programs array, if it has prop isSurvey === true, push to surveys arr
          programs
            .filter(x => x.displayInLibrary != false)
            .forEach(item => {
              if (item.isSurvey === true) {
                if (item.visibilityType === "PRIVATE") {
                  surveysArr.push(item);
                }
              } else {
                programsArr.push(item);
              }
            });
          programs = programsArr;
          // TODO: sort out active, future, and archived surveys
          // formats surveys and assigns to this.surveys
          this.formatSurveys(surveysArr);

          this.loadingPrograms = false;
          programs.forEach(program => {
            // We setup a second description attribute that has html stripped
            var tempDescription = program.description.replace(
              /<\/?[^>]+(>|$)/g,
              " "
            );
            program.descriptionNoTags = tempDescription.replace(/&nbsp;/g, " ");

            if (program.status === "Active") activePrograms.push(program);
            else if (program.status === "Scheduled")
              futurePrograms.push(program);
            else if (
              program.status === "Archived" &&
              program.ProgramCache.length
            )
              archivedPrograms.push(program);
          });

          //Sorts programs depending on status
          activePrograms.sort(function(a, b) {
            return new Date(a.endDate) - new Date(b.endDate);
          });
          futurePrograms.sort(function(a, b) {
            return new Date(a.startDate) - new Date(b.startDate);
          });
          archivedPrograms.sort(function(a, b) {
            return new Date(b.endDate) - new Date(a.endDate);
          });

          this.activePrograms = activePrograms;
          this.futurePrograms = futurePrograms;
          this.archivedPrograms = archivedPrograms;

          if (activePrograms.length < 1) {
            this.filters.future = true;
          }

          console.log("Active: ", activePrograms);
          console.log("Future: ", futurePrograms);
          console.log("Archived: ", archivedPrograms);
        }
      } catch (err) {
        console.log("Error fetching programs ", err);
        this.loadingPrograms = false;
        this.loadingError = true;
      }
    },
    loadProgram(program) {
      console.log("Loading program");
      if (program.formId) {
        this.$router.push({
          path: "form/" + program.formId
        });
      } else if (program.isSurvey === true) {
        // we need to load it like a survey!
        if (program.visibilityType === "PUBLIC") {
          console.log("pushing to surveys public ", program.learningModuleId);
          this.$router.push({
            path: "survey/public/" + program.learningModuleId.toString()
            // TODO: check about these
            // params: {
            // sourcePage: "programs",
            //   showNewUserDialogOnLoad: false,
            //   surveyId: program.learningModuleId.toString(),
            //   sourcePage: 'Programs',
            // },
          });
        } else {
          console.log("pushing to surveys private ", program.learningModuleId);
          this.$router.push({
            name: "surveyPrivate", //we know it's a private survey because public surveys can't be in programs
            params: {
              surveyId: program.learningModuleId.toString(),
              sourcePage: "programs"
            }
          });
        }
      } else {
        this.$router.push({
          name: "programdetails",
          params: {
            programPreloaded: program,
            programId: program.programId.toString(),
            sourcePage: "programs"
          }
        });
      }
    },
    timeRemainingMilliseconds(program) {
      // The time between now and the end date, in milliseconds
      const now = moment();
      const end = moment(program.endDate);
      // console.log(now, end);
      // console.log('milliseconds', end.diff(now));
      return end.diff(now);
    },
    daysSinceStart(program) {
      let today = new Date();
      let programStartDate = new Date(program.startDate);
      let diff = today.getTime() - programStartDate.getTime();
      let diffInDays = diff / (1000 * 3600 * 24);
      // console.log("diff in days");
      // console.log(diffInDays);
      return diffInDays;
    },
    // takes in an array of surveys, formats and returns the array
    formatSurveys(surveyArr) {
      // we will filter all not active surveys out
      let formattedSurveyArr = [];
      // surveyArr from initial GET programs request, we filtered it out
      // and now have to format it
      surveyArr.forEach(survey => {
        // We setup a second description attribute that has html stripped
        var tempDescription = survey.description.replace(
          /<\/?[^>]+(>|$)/g,
          " "
        );
        // adds new prop descriptionNoTags
        survey.descriptionNoTags = tempDescription.replace(/&nbsp;/g, " ");
        // console.log("formatting image url", survey.imageUrlSigned);
        // programs have imageURLs not imageUrls lol
        if (survey.imageUrlSigned && survey.imageUrlSigned !== null) {
          // delete this wrong case property to assign correct case
          delete survey.imageUrl;
          survey.imageURL = survey.imageUrlSigned;
        } else if (survey.imageUrl && survey.imageUrl !== null) {
          // assign url to variable, delete the wrong case property, and assign correct prop
          let url = survey.imageUrl;
          delete survey.imageUrl;
          survey.imageURL = url;
        }
        // console.log("formatted", survey.imageURL);

        // TODO: HOW WILL THIS WORK WITH PARTIALLY COMPLETED SURVEYS?
        // how to figure this out, maybe add up all of the cards for a total, then the progress, then divide
        // survey.progressPercent = survey.percentComplete * 100;
        survey.programId = `${survey.learningModuleId}-survey`;

        // only push if the status is active
        // if (survey.status === "Active") {
        formattedSurveyArr.push(survey);
        // }
      });

      this.surveys = formattedSurveyArr;
    },
    replaceQuery(sortBy, filterBy) {
      var existingQuery = JSON.parse(JSON.stringify(this.$route.query));
      if (sortBy) existingQuery.sortBy = sortBy;
      if (filterBy) existingQuery.filterBy = filterBy;
      // this.$router.replace({ query: existingQuery });
      console.log("New query ", existingQuery);
      this.$router.replace(
        {
          query: Object.assign(existingQuery)
        },
        () => {}
      );
    },
    updateSortBy(option) {
      console.log("updating sortby ", option);

      var sortOption = this.sortOptions.find(x =>
        x.values.includes(this.sortBy)
      );
      // If they're picking an option that's already selected, then they're just changing the sort order.
      if (sortOption == option) {
        var asc = this.sortBy.includes("asc");
        this.replaceQuery(option.key + "-" + (asc ? "desc" : "asc"), null);
      } else {
        this.replaceQuery(option.key + "-asc", null);
      }
    },
    updateFilterBy(option) {
      console.log("updating filter ", option);
      var filters = this.filterBy;
      // If they're picking an option that's already selected, then they're either filtering to just that, or everything
      const mainFilters = ["learning", "surveys", "forms"];
      if (mainFilters.includes(option)) {
        // If the option they select is not in the array, currently, then we filter just for that
        // If we've already got the option, then we do nothing (clicking a filter again)
        if (filters.includes(option)) return;
        filters.push(option);
        // remove anything not extraneous or the current selection
        filters = filters.filter(
          selected => !mainFilters.includes(selected) || option === selected
        );

        if (option == "learning") filters.push("active-programs");
      } else if (this.filterBy.includes(option)) {
        // We want to remove it
        var index = filters.findIndex(x => x == option);
        if (index !== -1) filters.splice(index, 1);
      } else {
        // We want to add it
        filters.push(option);
      }

      // Remove duplicates
      filters = [...new Set(filters)];

      this.replaceQuery(null, filters);
    },
    loadGivingWidget() {
      this.$store.dispatch("setDisplayWalletMenu", true);
      // We have to set the source page so we know when they click back, it closes the drawer
      this.$store.dispatch("setSourceWalletPage", "programs");
      this.$store.dispatch("setWalletMenuScreen", "donate");
    },
    async getForms() {
      try {
        console.log("Getting forms");
        this.loading.forms = true;
        const res = await FormService.getFormsV2();
        this.forms = res?.result?.rows;
      } catch (e) {
        console.log("Error loading Forms", e);
      } finally {
        this.loading.forms = false;
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "postItArray"]),
    // computedRobinMessage() {
    //   if (this.activePrograms.length > 0) {
    //     //Active programs are sorted so the soonest one to end is first
    //     var program = this.activePrograms[0];
    //     if (program.progressPercent >= 100) {
    //       return (
    //         "Great job! You've already met your goal for the " +
    //         program.displayName +
    //         " program."
    //       );
    //     } else {
    //       var remainder = 100 - parseInt(program.progressPercent);
    //       if (remainder == 100) {
    //         return (
    //           "Looks like you haven't started working towards your goal in the " +
    //           program.displayName +
    //           " program. Now is the perfect time to start!"
    //         );
    //       } else if (isNaN(remainder)) {
    //         return "Keep up the great work in your latest program!!!";
    //       } else {
    //         return (
    //           "You are only " +
    //           remainder +
    //           "% away from your goal in the " +
    //           program.displayName +
    //           " program! Keep it up!"
    //         );
    //       }
    //     }
    //   } else if (this.futurePrograms.length > 0) {
    //     program = this.futurePrograms[0];
    //     var time = moment(program.startDate).diff(moment(), "days");
    //     if (program.qualified) {
    //       if (time === 1) {
    //         return (
    //           "The " +
    //           program.displayName +
    //           " program is starting in just " +
    //           time +
    //           " day!"
    //         );
    //       } else {
    //         return (
    //           "The " +
    //           program.displayName +
    //           " program is starting in just " +
    //           time +
    //           " days!"
    //         );
    //       }
    //     } else {
    //       var qualifiedProgress = program.ProgramCache.find(
    //         x => x.type == "QUALIFIERS"
    //       );
    //       if (qualifiedProgress && qualifiedProgress.progressPercent >= 100) {
    //         return (
    //           "Great job! You're already qualified for the upcoming " +
    //           program.displayName +
    //           " program."
    //         );
    //       } else if (qualifiedProgress) {
    //         remainder = 100 - parseInt(qualifiedProgress.progressPercent);
    //         if (remainder == 100) {
    //           return (
    //             "Looks like you should get started working towards becoming qualified for the " +
    //             program.displayName +
    //             " program."
    //           );
    //         } else if (isNaN(remainder)) {
    //           return "Keep up the great work in your latest program!!!";
    //         } else {
    //           return (
    //             "You are only " +
    //             remainder +
    //             "% away from becoming qualified for the " +
    //             program.displayName +
    //             " program!"
    //           );
    //         }
    //       } else {
    //         return "Explore this page to see what upcoming programs you've got!";
    //       }
    //     }
    //   }
    //   return "Looks like you're not in any active programs. Keep an eye on this page to see what's coming up soon!";
    // },
    // TODO: make this a getter and a setter with a cache false
    // I had a bug that I can't reproduce, I selected surveys then clicked a bunch
    // then selected learning and there were 0
    computedVisiblePrograms() {
      console.log("Running");
      let programs = [];
      // type filter can be null, learning, or surveys
      // if (this.typeFilter === null) {
      //   programs = [...this.surveys];
      // } else if (this.typeFilter === 'learning') {
      //   programs = [];
      // } else if (this.typeFilter === 'survey') {
      //   // TODO: check about the filter functionality
      //   programs = [...this.surveys];
      // }
      // only want to do these if typeFilter !== survey
      // var programs = [...this.surveys];
      // if we are filtering for learning or all
      if (this.filterBy.includes("forms")) programs = [...this.forms];
      else if (this.filterBy.includes("learning")) {
        // if (this.filterBy.includes("surveys")) {
        //   // We show both surveys and programs
        //   if (!this.filterBy.includes("completed-surveys"))
        //     programs = [...this.surveys.filter(x => x.progressPercent < 100)];
        //   else programs = [...this.surveys];
        // } else {
        //   programs = [];
        // }
        if (this.filterBy.includes("active-programs")) {
          programs = programs.concat(this.activePrograms);
        }
        if (this.filterBy.includes("future-programs")) {
          programs = programs.concat(this.futurePrograms);
        }
        if (this.filterBy.includes("archived-programs")) {
          programs = programs.concat(this.archivedPrograms);
        }
      } else if (this.filterBy.includes("surveys")) {
        if (!this.filterBy.includes("completed-surveys"))
          programs = [...this.surveys.filter(x => x.progressPercent < 100)];
        else programs = [...this.surveys];
      }

      // if there is no search term
      if (
        this.search === null ||
        this.search == "" ||
        this.search === undefined ||
        this.search === "null" ||
        this.search.replace(/\s+/g, "").length === 0
      ) {
        if (this.sortBy) {
          if (this.sortBy.includes("progress")) {
            console.log("progress sort");
            programs.sort((a, b) =>
              this.sortBy.includes("asc")
                ? a.percentComplete - b.percentComplete
                : b.percentComplete - a.percentComplete
            );
          } else if (this.sortBy.includes("time-remaining")) {
            console.log("time sort");
            var tempPrograms = [];
            // programs.sort((a, b) => {
            if (this.sortBy.includes("asc")) {
              tempPrograms = programs
                .filter(x => x.endDate)
                .sort((a, b) => {
                  if (isNaN(this.timeRemainingMilliseconds(a))) {
                    return 1;
                  } else if (isNaN(this.timeRemainingMilliseconds(b))) {
                    return -1;
                  }
                  return (
                    this.timeRemainingMilliseconds(a) -
                    this.timeRemainingMilliseconds(b)
                  );
                });
              tempPrograms = tempPrograms.concat(
                programs.filter(x => !x.endDate)
              );
            } else {
              tempPrograms = programs.filter(x => !x.endDate);
              tempPrograms = tempPrograms.concat(
                ...programs
                  .filter(x => x.endDate)
                  .sort((a, b) => {
                    if (isNaN(this.timeRemainingMilliseconds(a))) {
                      return 1;
                    } else if (isNaN(this.timeRemainingMilliseconds(b))) {
                      return -1;
                    }
                    return (
                      this.timeRemainingMilliseconds(b) -
                      this.timeRemainingMilliseconds(a)
                    );
                  })
              );
            }
            programs = tempPrograms;
          } else if (this.sortBy.includes("name")) {
            console.log("name sort");
            programs.sort((a, b) =>
              this.sortBy.includes("asc")
                ? (a.displayName || a.name).localeCompare(
                    b.displayName || b.name
                  )
                : (b.displayName || b.name).localeCompare(
                    a.displayName || a.name
                  )
            );
          } else {
            programs.sort(
              (a, b) => (b.displayName || b.name) - (a.displayName || a.name)
            );
          }
        }
        // }

        return programs;
      }

      const preparedQuery = fz.prepareQuery(this.search);
      const scores = {};

      programs = programs
        .map(item => {
          //Programs page
          var scorableFields = [
            item.programId || item.formId,
            item.displayName || item.name
          ];
          scorableFields = scorableFields.map(toScore =>
            fz.score(toScore, this.search, { preparedQuery })
          );

          scores[item.programId || item.formId] = Math.max(...scorableFields);

          return item;
        })
        .filter(item => scores[item.programId || item.formId] > 1)
        .sort(
          (a, b) =>
            scores[b.programId || b.formId] - scores[a.programId || a.formId]
        );
      return programs;
    },
    sortBy: {
      cache: false,
      get: function() {
        // We parse sortBy sent in through query params and if it's valid, then we pass it on. Otherwise we default
        let options = [
          "name-asc",
          "name-desc",
          "progress-asc",
          "progress-desc",
          "time-remaining-asc",
          "time-remaining-desc"
        ];
        let query = this.$route.query.sortBy;
        if (
          query &&
          !Array.isArray(query) &&
          options.includes(query.toLowerCase())
        )
          return query.toLowerCase();
        else if (query && Array.isArray(query)) {
          var foundValid = null;
          // We have multiple options so we return the first valid one
          query.forEach(item => {
            if (options.includes(item.toLowerCase()) && !foundValid) {
              foundValid = item.toLowerCase();
            }
          });
          if (foundValid) return foundValid;
        }

        return "time-remaining-asc";
      }
    },
    filterBy: {
      cache: false,
      get: function() {
        // We parse sortBy sent in through query params and if it's valid, then we pass it on. Otherwise we default
        const mainFilters = ["forms", "surveys", "learning"];
        let options = [
          "forms",
          "surveys",
          "learning",
          "active-programs",
          "future-programs",
          "archived-programs",
          "completed-surveys"
        ];
        let query = this.$route.query.filterBy;
        if (
          query &&
          !Array.isArray(query) &&
          options.includes(query.toLowerCase())
        )
          return [query.toLowerCase()];
        else if (query && Array.isArray(query)) {
          var validOptions = [];
          // We have multiple options so we return the first valid one
          query.forEach(item => {
            if (
              options.includes(item.toLowerCase()) &&
              // We don't already have a main filter in there
              (!mainFilters.includes(item.toLowerCase()) ||
                !validOptions.find(selected => mainFilters.includes(selected)))
            ) {
              validOptions.push(item.toLowerCase());
            }
          });
          if (validOptions.length > 0) return validOptions;
        }

        return ["learning", "active-programs"];
      }
    }
  }
};
</script>

<style scoped>
.img {
  border-radius: 5px;
  border: 1px solid;
  border-color: var(--v-lightGrey-base);
}
.brand-background {
  background-color: var(--v-brand-base);
}
.border-radius {
  border-radius: 5px;
}
.skeloton-progress-circle {
  transform: scale(2.4);
}

.mandatory-flag {
  align-items: baseline;
}

.new-flag {
  align-items: baseline;
}

.new-flag-icon {
  filter: drop-shadow(0 0 3px);
}

.program-card-div {
  max-height: 325px;
}

.program-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.type-filter-div {
  min-width: 100%;
  margin: 0 auto;
}

.type-filter-btn {
  width: 140px !important;
}

.active-btn {
  opacity: 0.8;
}

.progress-bar {
  width: 100%;
  max-width: 350px;
  border-radius: 20px;
}

/* .progress-bar-div {
  margin-left: 2px;
} */

/* small tablet and phone breakpoint, moving progress and title to bottom */
@media only screen and (max-width: 700px) {
  .small-screen-title .program-date-string {
    flex-wrap: wrap;
  }
}
/* removes the x axis margins from the buttons when in a column layout */
@media only screen and (max-width: 1200px) and (min-width: 960px) {
  .type-filter-div {
    flex-direction: column !important;
    align-items: start !important;
  }
  .type-filter-btn {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
/* small screens, so that the filters are layed out in a row */
@media only screen and (max-width: 960px) and (min-width: 600px) {
  .type-filter-div {
    width: auto !important;
    min-width: auto !important;
    margin: 0;
  }
  .small-screen-div {
    justify-content: space-between;
    align-items: baseline;
  }
}
</style>
